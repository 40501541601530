.App {
	background-color: #f7f8f9;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.App-header {
	flex: 0;
	background-color: #fff;
	text-align: center;
	padding: 1rem;
}

.App-logo {
	width: 80%;
	max-width: 400px;
}

.App-body {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.App-body-inner {
	width: 100%;
	max-width: 1200px;
	min-height: 100%;
	background-color: white;
	padding: 1rem;
}

.App-footer {
	flex: 0;
	padding: 0.5rem;
	background-color: #55555e;
	text-align: center;
	color: #fff;
	font-size: 15px;
}

.App-footer a {
	color: inherit;
	text-decoration: dotted underline;
}

.MuiFormLabel-asterisk {
	display: none;
}

.booking-step-header {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.booking-step-header h2 {
	font-size: 1.5rem;
}

.form-error {
	color: #dc143c; /* equivalent to the named color 'crimson'; duplicated in theme */
	font-weight: bold;
}

.form-warning {
	color: #daa520; /* equivalent to the named color 'goldenrod'; duplicated in theme */
	font-weight: bold;
}

.success {
	color: #008000; /* equivalent to the named color 'green'; duplicated in theme */
	font-weight: bold;
}

.input-widget-container {
	max-width: 500px;
	margin-bottom: 2rem;
}

input.input {
	min-height: 2.5rem;
	color: black; /* slightly blacker than body text */
}

.date-field input {
	min-height: 2.5rem;
	border: 0;
	background-color: inherit;
}

label {
	margin-bottom: 0.25rem;
}

label.radio-or-checkbox {
	margin-bottom: 0;
}

.school-picker-option {
	display: flex;
	flex-direction: column;
}

.school-picker-option-main {
	display: block;
	margin-right: 1rem;
	font-weight: bold;
}

.school-picker-option-secondary {
	display: block;
	font-size: 0.8rem;
}

.schools-chosen-for-conference, .non-conference-games {
	margin-left: 0;
	margin-bottom: 0;
}

.schools-chosen-for-conference li, .non-conference-games li {
	list-style-position: inside;
}

.practice-questions-group {
	margin-bottom: 2rem;
}

.practice-questions-group h3 {
	font-size: 1.5rem;
	margin-bottom: 0;
}

.practice-questions-group h4 {
	font-size: 1.25rem;
	margin-top: 1rem;
	margin-bottom: 0;
}

.order-review.invoice-wrapper {
	max-width: 600px;
}

.order-review ul {
	margin-left: 0;
	list-style-type: none;
}

.order-review-key {
	font-weight: bold;
}

span.order-review-key::after {
	content: ':';
}

.invoice th, .invoice td {
	padding: 0.25rem 0.5rem 0.25rem 0.25rem;
}

.invoice thead th {
	font-weight: bold;
}

.invoice tfoot th, .invoice tfoot td {
	font-size: 1rem;
	font-weight: bold;
}

address {
	font-style: normal;
	padding-left: 1rem;
}

p + address {
	margin-top: -1.5rem; /* undo the spacing between paragraphs */
}

.busy-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.75);
	z-index: 10000;
}

.busy-overlay .busy-overlay-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

fieldset {
	border: 1px solid gray;
	border-radius: 0.2rem;
	padding: 1rem;
	margin-bottom: 2rem;
}

.filter-option {
	padding-right: 1rem;
}

.booking-summary-row {
	cursor: pointer;
}

.MuiTooltip-tooltip p {
	margin-bottom: 0;
}

.MuiTooltip-tooltip ul, .MuiTooltip-tooltip ol {
	margin-left: 0.5rem;
}

.booking-data > div {
	display: block;
	margin-bottom: 0.5rem;
}

.booking-data dt, .booking-data dd {
	display: inline-block;
}

dt {
	font-weight: 600;
}

dd ul, dd ol {
	margin-left: 0;
}

.booking-data dd {
	margin-left: 0.5rem;
	margin-bottom: 0;
}

.display-or-edit-container-outer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.display-or-edit-container-inner {
	flex: 1;
	max-width: 500px;
	margin-bottom: 1rem;
}

.preserve-whitespace {
	white-space: pre-wrap;
}

.two-columns {
	column-count: 2; /* TODO: only on large enough screens */
}
